import { Route, Routes } from "react-router-dom";

import { AuthProvider, ProtectedPage, RequireAuth } from "./auth";

import { AppHeader } from "./components/app-header/AppHeader";

import { MainModule } from "./components/main-module/MainModule";
import { LoginWidget } from "./components/login-widget/LoginWidget";

import "./App.scss";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MainModule />} />
      <Route path="/login" element={<LoginWidget />} />
      <Route
        path="/protected"
        element={
          <RequireAuth>
            <ProtectedPage />
          </RequireAuth>
        }
      />
    </Routes>
  );
};

export const App = () => {
  return (
    <AuthProvider>
      <div className="app">
        <AppHeader />

        <AppRoutes />
      </div>
    </AuthProvider>
  );
};
